.wrapper {
  display: flex;
  gap: 20px;
  width: 90%;
  margin: 30px auto;
}
.myPhotoDiv {
  width: 48%;
}
.myPhoto {
  width: 100%;
}

.text {
  width: 50%;
}
.bio {
  font-size: 1rem;
}

@media (max-width: 900px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .myPhotoDiv {
    width: 90%;
  }
  .text {
    width: 90%;
  }
}
