.footer {
  width: 90%;
  height: 100px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  background-color: #cfb419cc;
}

.footerLinks {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  margin-left: 30px;
  color: white;
}

.footerLinks a {
  text-decoration: none;
  font-family: "Julius Sans One", sans-serif;
  font-size: 15px;
  font-weight: 800;
  font-style: normal;
  color: white;
  transition: 0.4s;
}

.copyright {
  margin-top: auto;
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;
}

.footerLinks a:hover,
.icon:hover {
  color: rgba(0, 0, 0, 0.587);
}

.footerLinks a:active,
.icon:active {
  color: rgb(0, 0, 0);
}

.footerLinksWrapper {
  display: flex;
}

.icon {
  font-size: 20px;
  color: white;

  text-decoration: none;
  transition: 0.3s;
}

.iconsDiv {
  display: flex;
  margin-left: auto;
  margin-top: 15px;
  margin-right: 25px;
  gap: 16px;
}
.iconsDiv a {
  text-decoration: none;
}

@media (max-width: 700px) {
  .footer {
    width: 100%;
  }

  .copyright {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .footer {
    width: 100%;
  }

  .footerLinks {
    margin-left: 12px;
  }

  .icon {
    font-size: 19px;
  }

  .copyright {
    font-size: 12px;
  }
}

@media (max-width: 370px) {
  .footerLinksWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .iconsDiv {
    margin-left: 0;
  }

  .footerLinks {
    margin-left: 0;
  }

  .icon {
    font-size: 17px;
  }
}
