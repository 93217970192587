* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.diashow {
  width: 100%;
  height: calc(80vw / 1.7);
  margin-top: 10px;
  overflow: hidden;
}

.diashow-wrapper {
  width: 100%;
  position: relative;
}

.imageWrapper {
  width: 100%;
  position: absolute;
  opacity: 0;
  /* transform: scale(1); */
  transition: 2.5s ease-in-out;
}

.imageWrapper-active {
  opacity: 1;
}

.diashow-image {
  width: 100%;
}

@media (max-width: 1300px) {
  .diashow {
    height: calc(85vw / 1.7);
  }
}

@media (max-width: 900px) {
  .diashow {
    height: calc(90vw / 1.7);
  }
}

@media (max-width: 450px) {
  .diashow {
    height: calc(100vw / 1.7);
  }
}
