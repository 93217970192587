h5 {
  font-family: system-ui;
  font-size: 20px;
  margin: 15px 0;
}

.impressum {
  width: 60vw;
  margin: auto;
}
