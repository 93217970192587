* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.xbutton {
  color: white;
  font-size: 55px;
  position: fixed;
  top: 25px;
  right: 40px;
  cursor: pointer;
}

.mobileNavBar {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #cfb419;
  backdrop-filter: blur(3px);
  z-index: 100;
  transform-origin: top;
}

.navList {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%);
}

.linkWrapper {
  padding: 5px;
  margin-bottom: 20px;
  overflow: hidden;
}

.navList a {
  font-family: "Julius Sans One", sans-serif;
  font-size: 25px;
  color: white;
  text-decoration: none;
}

.navbar {
  display: flex;
  align-items: flex-end;
  padding-top: 12px;
}

.logo {
  width: 150px;
  margin-left: 10px;
}

.navbar-list {
  display: flex;
  list-style: none;
  margin-left: auto;
}

.navbar-list a {
  color: #404040;
  text-decoration: none;
  font-family: "Julius Sans One", sans-serif;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
}

.homeLink,
.galerieLink,
.uebermichLink,
.kontaktLink {
  margin-right: 20px;
  transition: all 0.3s;
}

.homeLink:hover,
.galerieLink:hover,
.uebermichLink:hover,
.kontaktLink:hover {
  transform: translate(0px, -6px) !important;
}

.active {
  color: #cfb419 !important;
}

.menuIcon {
  display: none;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 30;
  margin-right: 25px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .logo {
    width: 110px;
  }
  .navbar-list a {
    font-size: 19px;
  }

  .homeLink,
  .galerieLink,
  .uebermichLink,
  .kontaktLink {
    margin-right: 15px;
  }
}

@media (max-width: 600px) {
  .logo {
    width: 90px;
  }
  .navbar-list a {
    font-size: 15px;
  }
}

@media (max-width: 550px) {
  .menuIcon {
    display: block;
    margin-left: auto;
  }
  .navbar-list {
    display: none;
  }

  .logo {
    width: 80px;
  }
}
