* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.element-01 {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 5fr 7fr;
  gap: 20px;
  overflow: hidden;
}

.element-02,
.element-03 {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  overflow: hidden;
}

.textEl01,
.textEl02,
.textEl03 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  gap: 5px;
  border: solid 1px #cfb419;
}

h1 {
  text-align: center;
  font-family: "Julius Sans One", sans-serif;
  color: #404040;
  font-size: 40px;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-family: "Julius Sans One", sans-serif;
  color: #cfb419;
  font-size: 24px;
  font-weight: 400;
}

.h2Text {
  text-align: center;
  font-family: "Julius Sans One", sans-serif;
  line-height: 5px;
  color: #cfb419;
  font-size: 24px;
  font-weight: 400;
}

.spruch {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 15px;
}

.spruch-wortWrapper {
  overflow: hidden;
}

.spruch-span {
  display: inline-block;
}

h3 {
  font-family: "Julius Sans One", sans-serif;
  color: #cfb419;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  margin: 15px 0;
}

h4 {
  font-family: "Julius Sans One", sans-serif;
  color: #404040;
  font-size: 23px;
  font-weight: 600;
}

p {
  font-family: system-ui;
  font-size: 1.03rem;
}

@media (max-width: 1330px) {
  .textEl01 {
    padding: 15px;
  }

  .element-01 {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 7fr 5fr;
  }

  .element-02,
  .element-03 {
    width: 100%;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 22px;
  }
}

@media (max-width: 1100px) {
  .textEl01,
  .textEl02 {
    padding: 15px;
  }
  .textEl02 {
    order: -1;
  }

  .element-01,
  .element-02,
  .element-03 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 30px;
  }
}

@media (max-width: 450px) {
  .spruch {
    width: 97%;
    margin: 5px auto;
    pad: 3px;
  }

  .element-01,
  .element-02,
  .element-03 {
    width: 92%;
    display: flex;
    flex-direction: column;
  }

  .spruch {
    gap: 8px;
  }

  h3 {
    font-size: 22px;
  }
}
